<template>
  <div class="kneeQ"> 
    <h2>膝关节调查问卷</h2>
    <div v-if="steps == 0" class="tips">
          <van-form @submit="nextStep">
            <p class="question">1. 您的膝关节是否不能独立站立或行走?</p>
            <van-radio-group v-model="kneeQData.screeningQ1" direction="horizontal">
              <van-radio name="true">是</van-radio>
              <van-radio name="false">否</van-radio>
            </van-radio-group>

            <p class="question">2. 您的膝关节是否有明显肿胀，剧烈疼痛?</p>
            <van-radio-group v-model="kneeQData.screeningQ2" direction="horizontal">
              <van-radio name="true">是</van-radio>
              <van-radio name="false">否</van-radio>
            </van-radio-group>

            <p class="question">3. 您的膝关节在活动过程中是否经常有绞索感?</p>
            <van-radio-group v-model="kneeQData.screeningQ3" direction="horizontal">
              <van-radio name="true">是</van-radio>
              <van-radio name="false">否</van-radio>
            </van-radio-group>
           
            <van-button block type="info" native-type="submit">下一步</van-button>
          </van-form>          
        </div>
        <div v-if="steps == 1">         
          <h2>疼痛：</h2>
          <van-form @submit="lastStep">            
            <p class="question">1. 您多久会经历一次膝关节疼痛?</p>
            <div class="slider-wrap">
              <van-slider v-model="kneeQData.painQ1" max="4" />
              <div class="split-line"><div></div><div></div><div></div><div></div></div>
              <div class="marks">
                <div class="marks-text">从不</div>
                <div class="marks-text">每月</div>
                <div class="marks-text">每周</div>
                <div class="marks-text">每天</div>
                <div class="marks-text">总是</div>
              </div>
            </div>
            <div class="line"></div>
            <p class="question">在接下来的活动中，您上周经历过什么样的膝关节疼痛？</p>
            <p class="question">2. 在平地上行走。</p>
            <div class="slider-wrap">
              <van-slider v-model="kneeQData.painQ2" max="4" />
              <div class="split-line"><div></div><div></div><div></div><div></div></div>
              <div class="marks">
                <div class="marks-text">没有</div>
                <div class="marks-text">轻微</div>
                <div class="marks-text">中度</div>
                <div class="marks-text">重度</div>
                <div class="marks-text">严重</div>
              </div>
            </div>
            <p class="question">3. 上下楼梯。</p>
            <div class="slider-wrap">
              <van-slider v-model="kneeQData.painQ3" max="4" />
              <div class="split-line"><div></div><div></div><div></div><div></div></div>
              <div class="marks">
                <div class="marks-text">没有</div>
                <div class="marks-text">轻微</div>
                <div class="marks-text">中度</div>
                <div class="marks-text">重度</div>
                <div class="marks-text">严重</div>
              </div>
            </div>
            <p class="question">4. 坐着或躺着。</p>
            <div class="slider-wrap">
              <van-slider v-model="kneeQData.painQ4" max="4" />
              <div class="split-line"><div></div><div></div><div></div><div></div></div>
              <div class="marks">
                <div class="marks-text">没有</div>
                <div class="marks-text">轻微</div>
                <div class="marks-text">中度</div>
                <div class="marks-text">重度</div>
                <div class="marks-text">严重</div>
              </div>
            </div>
            <div class="line"></div>
            <h2>功能，日常活动：</h2>
            <p class="question">以下问题与您的身体功能有关。指的是您四处活动和照顾自己的能力。对于以下每一项活动，请注明您在上周因膝关节而经历的困难程度。</p>
            <p class="question">5. 从坐位起来。</p>
            <div class="slider-wrap">
              <van-slider v-model="kneeQData.functionQ1" max="4" />
              <div class="split-line"><div></div><div></div><div></div><div></div></div>
              <div class="marks">
                <div class="marks-text">没有</div>
                <div class="marks-text">轻微</div>
                <div class="marks-text">中度</div>
                <div class="marks-text">重度</div>
                <div class="marks-text">严重</div>
              </div>
            </div>
            <p class="question">6. 站立。</p>
            <div class="slider-wrap">
              <van-slider v-model="kneeQData.functionQ2" max="4" />
              <div class="split-line"><div></div><div></div><div></div><div></div></div>
              <div class="marks">
                <div class="marks-text">没有</div>
                <div class="marks-text">轻微</div>
                <div class="marks-text">中度</div>
                <div class="marks-text">重度</div>
                <div class="marks-text">严重</div>
              </div>
            </div>
            <p class="question">7. 上下车。</p>
            <div class="slider-wrap">
              <van-slider v-model="kneeQData.functionQ3" max="4" />
              <div class="split-line"><div></div><div></div><div></div><div></div></div>
              <div class="marks">
                <div class="marks-text">没有</div>
                <div class="marks-text">轻微</div>
                <div class="marks-text">中度</div>
                <div class="marks-text">重度</div>
                <div class="marks-text">严重</div>
              </div>
            </div>
            <p class="question">8. 旋转或扭动膝关节。</p>
            <div class="slider-wrap">
              <van-slider v-model="kneeQData.functionQ4" max="4" />
              <div class="split-line"><div></div><div></div><div></div><div></div></div>
              <div class="marks">
                <div class="marks-text">没有</div>
                <div class="marks-text">轻微</div>
                <div class="marks-text">中度</div>
                <div class="marks-text">重度</div>
                <div class="marks-text">严重</div>
              </div>
            </div>
            <div class="line"></div>
            <h2>生活质量：</h2>
            <p class="question">9. 您多久会意识到您的膝关节问题？</p>
            <div class="slider-wrap">
              <van-slider v-model="kneeQData.qolQ1" max="4" />
              <div class="split-line"><div></div><div></div><div></div><div></div></div>
              <div class="marks">
                <div class="marks-text">从不</div>
                <div class="marks-text">每月</div>
                <div class="marks-text">每周</div>
                <div class="marks-text">每天</div>
                <div class="marks-text">一直</div>
              </div>
            </div>
            <p class="question">10. 您是否改变了您的生活方式，以避免那些可能对您的膝关节造成损伤的活动？</p>
            <div class="slider-wrap">
              <van-slider v-model="kneeQData.qolQ2" max="4" />
              <div class="split-line"><div></div><div></div><div></div><div></div></div>
              <div class="marks">
                <div class="marks-text">没有</div>
                <div class="marks-text">轻微</div>
                <div class="marks-text">适度</div>
                <div class="marks-text">较多</div>
                <div class="marks-text">完全</div>
              </div>
            </div>
            <p class="question">11. 您是否对您的膝关节缺乏信心？</p>
            <div class="slider-wrap">
              <van-slider v-model="kneeQData.qolQ3" max="4" />
              <div class="split-line"><div></div><div></div><div></div><div></div></div>
              <div class="marks">
                <div class="marks-text">没有</div>
                <div class="marks-text">轻微</div>
                <div class="marks-text">中度</div>
                <div class="marks-text">重度</div>
                <div class="marks-text">严重</div>
              </div>
            </div>
            <p class="question">12. 总的来说，您的膝关节有多大的问题？</p>
            <div class="slider-wrap">
              <van-slider v-model="kneeQData.qolQ4" max="4" />
              <div class="split-line"><div></div><div></div><div></div><div></div></div>
              <div class="marks">
                <div class="marks-text">没有</div>
                <div class="marks-text">轻微</div>
                <div class="marks-text">中度</div>
                <div class="marks-text">重度</div>
                <div class="marks-text">严重</div>
              </div>
            </div>
            <van-button block type="info" native-type="submit">提交</van-button>
          </van-form>
        </div>
  </div>
</template>
<script>
import { addKneeQ } from "@/api/getData"
import { Toast } from "vant"

export default {
  name: "kneeQ",
  data() {
    return {
      steps:0,
      qName:'',
      kneeQData:{
        userId: '',
        screeningQ1 : '',// '是否不能独立站立或行走',
        screeningQ2 : '',// '是否有明显肿胀，剧烈疼痛',
        screeningQ3 : '',// '是否经常有绞索感',
        painQ1 : 0,// '疼痛问题1',
        painQ2 : 0,// '疼痛问题2',
        painQ3 : 0,// '疼痛问题3',
        painQ4 : 0,// '疼痛问题4',
        functionQ1 : 0,// '功能问题1',
        functionQ2 : 0,// '功能问题2',
        functionQ3 : 0,// '功能问题3',
        functionQ4 : 0,// '功能问题4',
        qolQ1 : 0,// '生活问题1',
        qolQ2 : 0,// '生活问题2',
        qolQ3 : 0,// '生活问题3',
        qolQ4 : 0,// '生活问题4',
      }      
    }
  },
  methods: {   
    //下一步
    nextStep(){
      if (this.steps === 0 && (!this.kneeQData.screeningQ1 || !this.kneeQData.screeningQ2 || !this.kneeQData.screeningQ3)) {
        return Toast('请填写全部问卷')
      }
      if(this.kneeQData.screeningQ1 == 'true'
      || this.kneeQData.screeningQ2 == 'true'
      || this.kneeQData.screeningQ3 == 'true'
      ){
        this.$confirm('您膝关节的问题较为严重，不适宜开展居家运动训练，建议到专业骨关节科医生处就诊。', '温馨提示', {
          confirmButtonText: '我知道了',
          type: 'warning',
          showCancelButton: false,
          confirmButtonClass: 'confirm-button',
          center: true
        }).then(() => {
          this.steps = 1       
        }).catch(()=>{})
      }else{
        this.steps = 1
      }
      document.documentElement.scrollTop = document.body.scrollTop = 0  
    },
    //提交表单
    lastStep(){
      addKneeQ(this.kneeQData).then(res => {
        this.$message.success('已成功添加膝关节训练方案!');
        this.$router.push({path: "/",query: { preId: res.data}})
      })        
    },
  },
  mounted() {
    this.kneeQData.userId = this.vUserId;
  }
}
</script>

<style lang="scss" scoped>
.kneeQ {
  padding: 0 .16rem;
  background: url(../../assets/img/bg_pic.png) no-repeat;
  background-size: 100% auto;

  h2 {
    padding-top: 0.48rem;
    font-size: .18rem;
    font-weight: 700;
    line-height: 0.32rem;
    opacity: 0.9;
  }

  .tips {
    p {
      font-size: .14rem;
      line-height: .24rem;
      color: #3D3D3D;
    }
  }

  .van-form {
    font-size: 0.14rem;
    font-weight: 700;

    .question {
      line-height: 0.24rem;
      margin: 0.48rem 0 0.16rem;
      color: #3d3d3d;
    }

    :deep(.van-radio) {
      width: 0.61rem;
      height: 0.41rem;
      align-items: center;
      justify-content: center;
      border-radius: 0.04rem;

      .van-radio__icon {
        width: 0.16rem;
        height: 0.16rem;
      }

      .van-radio__label {
        margin-left: 0.1rem;
      }

      .van-radio__icon--checked .van-icon {
        background-color: #24bbd3 !important;
        border-color: #24bbd3 !important;
      }

      .van-radio__icon.van-radio__icon--checked + .van-radio__label {
        color: #24bbd3;
      }
    }

    .van-radio--horizontal {
      margin-right: 0;
    }

    .van-radio + .van-radio {
      margin-left: 0.26rem;
    }

    .van-button {
      height: 0.48rem;
      margin: 0.64rem 0 0.32rem;
      border-radius: 0.4rem;
    }
  }
}
</style>